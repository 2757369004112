.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 600px;
    padding: 10px;
    background-color: #fff;
    border-bottom: 1px solid var(--primary);
}

.header__title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.header__title {
    font-size: 25px;
    color: #000000;
}

.back-arrow {
    cursor: pointer;
}


.header__contacts-link {
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: var(--primary);
    text-decoration: none;
    margin-bottom: 0;
}




.header__catalog-list {
    list-style: none;
    margin-top: 15px;
    width: 100%;
    overflow: auto;
    display: flex;
    align-items: flex-start;
}

.header__catalog-item {
    background-color: var(--primary);
    margin: 0 10px;
    padding: 5px 15px;
    border-radius: 4px;
}

.header__catalog-item:first-child {
    margin-left: 0;
}

.header__catalog-link {
    white-space: nowrap;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}