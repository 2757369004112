.contacts__wrapper {
    margin-top: 130px;
}

.social-net__wrapper {
    margin-bottom: 20px;
}


.contacts__instagram {
    margin-right: 20px;
}

.contacts__tel {
    display: block;
    color: var(--black);
}

.contacts__address {
    color: var(--black);
    margin-top: 20px;
    margin-bottom: 30px;
}