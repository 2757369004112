.menu-list {
    display: flex;
    flex-direction: column;
    margin-top: 130px;
}

.menu-item {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-color: #00000080;
    background-blend-mode: overlay;
    text-decoration: none;
    color: #fff;
    font-size: 40px;
    margin-bottom: 20px;
    border-radius: 7px;
}