* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

::-webkit-scrollbar {
    width: 0;
}

:root {
    --primary: #ED9E00;
    --black: #592C00;
}

.main {
    max-width: 600px;
    margin: 0 auto;
    padding: 10px;
}