.product-info__block {
    margin-top: 130px;
}

.product-info__img {
    width: 100%;
}

.product-info__des {
    color: var(--black);
    font-size: 17px;
    margin-top: 5px;
}

.product-info__price {
    color: var(--primary);
    font-size: 25px;
    font-weight: bold;
    margin: 10px 0;
}

.product-info__cooking-time{
    color: var(--black);
}