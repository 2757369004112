.card {
    display: block;
    width: 49%;
    margin-bottom: 30px;
    text-decoration: none;
}

.card__image {
    width: 100%;
}

.card__info-block {
    margin-top: 10px;
}

.card__title {
    color: var(--black);
    font-size: 18px;
}

.card__price {
    color: var(--primary);
    margin: 5px 0;
    font-size: 20px;
    font-weight: bold;
}

.card__cooking-time {
    display: flex;
    align-items: center;
    color: var(--black);
}

.card__cooking-time-icon {
    margin-right: 5px;
}