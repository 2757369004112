.logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
}




.lang-wrapper {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 100px auto 0 auto;
}

.lang-btn {
    color: var(--black);
    background-color: var(--primary);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
}



.lang-flag {
    margin-right: 50px;
}